body {
  margin:0;
  padding:0;
}

html {
  margin:0;
  padding:0;
}

@font-face {
  font-family: 'Berkeley Mono';
  src: url(./assets/type/BerkeleyMono-Regular.woff2) format('woff2'),
       url(./assets/type/BerkeleyMono-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}